.button {
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background: #f0f0f0;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
  position: relative;
  z-index: 200;
}
.arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
