.buttonOrder {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  padding: 11px 40px;
  background: #4c897a;
  border-radius: 10px;
}
.modalMat {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(98, 98, 98, 0.5);
  z-index: 100;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 437px;
  max-height: 95vh;
  background: #f9f9f9;
  border: 0.3px solid #76838f;
  border-radius: 10px;
  padding: 30px;
  overflow-y: auto;
}
.closeForm {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background: none;

  &:hover {
    cursor: pointer;
  }
}
.modalTitle {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  margin-bottom: 26px;
}
.orderName {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  margin-bottom: 24px;
}
.order {
  margin-right: 8px;
}
.orderNames,
.productsNames {
  position: relative;
}
.orderList,
.productsList {
  display: flex;
  flex-direction: column;
  width: max-content;
  max-width: 150px;
  min-height: 30px;
  max-height: 260px;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  overflow-y: auto;
  box-shadow: 0 0 2px rgba(0, 0, 0, .25);
  position: absolute;
  top: 120%;
  left: 0;
  z-index: 10;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f9f9fd;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #a8d3c933;
  }
  &::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: #f9f9fd;
  }
}
.orderItem {
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #76838F;

  &:hover {
    background: #b3b6b8;
    cursor: pointer;
  }
}
.orderNameWrap,
.dataFieldInputWrap {
  & span {
    font-size: 11px;
    width: max-content;
    bottom: -14px;
  }
}
input.treatyFieldInput:not([type='radio']):not([type=checkbox]) {
  width: 82px;
  min-height: 17px;
  padding: 6px;
}
.data {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
}
.item {
  display: flex;
  align-items: center;
  height: 31px;
  margin-bottom: 10px;
}
.itemName {
  width: 159px;
  padding-right: 20px;
}
input.dataFieldInput:not([type='radio']):not([type=checkbox]) {
  padding: 6px 11px;
}
.dataFieldInputWrap {
  width: 218px;
  height: 100%;
}
.dataFieldTextarea {
  width: 100%;
  height: 69px;
  padding: 10px 20px;
  background: #FFFFFF;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 10px 0 30px;
  resize: none;
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
.buttonSend {
  min-width: 165px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding: 6px 20px;
  background: #4c897a;
  border: 0.1px solid #76838f;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}
div.dataPicker {
  width: 200px;
  height: 31px;

  & button {
    top: 5px;
  }
}