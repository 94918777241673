
.userData {
  display: grid;
  grid-template-areas: 'data document';
  grid-template-columns: 424px 319px;
  grid-gap: 50px;
  margin-bottom: 57px;
}

.userInfo {
  grid-area: data;
  display: grid;
}

.buttons {
  margin-bottom: 11px;
}

.data {
  grid-area: data;

  & .userTitle,
  .userValue {
    display: inline-block;
    width: 100%;
    height: 31px;
    font-size: 14px;
    line-height: 17px;
    color: #1e252b;
    padding: 7px 10px;
  }

  & .userTitle {
    font-weight: 500;
    background: #ccc;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  }

  & .userValue:not([type='radio']):not([type=checkbox]):not([type=checkbox]) {
    height: 31px;
    padding: 7px 10px;
    font-weight: 400;
    background: #fff;
    border-radius: 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    position: relative;
  }
}

.rolesList {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 31px;
  left: 0;
  background: #f5f5f5;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.roles {
  position: relative;
}

.role {
  display: inline-block;
  padding: 8px 10px;

  &:hover {
    background: rgba(208, 231, 226, 1);
    cursor: pointer;
  }
}

.btnChange {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0096ff;
  background: none;
  padding: 5px 0;
}

.user {
  display: grid;
}

.userItem {
  height: 31px;
  display: grid;
  grid-template-columns: 153px 271px;
}

.userDocument {
  grid-area: document;
}

.documentTitle,
.file,
.fileName {
  color: #1e252b;
}

.fileName {
  margin-bottom: 20px;
}

.documentTitle {
  margin-bottom: 22px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
