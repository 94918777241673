.contentPrice {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  padding: 7px;
  background: #ccc;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

  &:hover {
    background: #f2fdfa;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
}

.contentPrice {
  background: #fff;
  position: relative;

  & .active, .inactive, .deleted {
    display: inline-block;
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(-50%);
  }
  & .active {
    background: #4c897a;
    border-radius: 50%;
  }
  & .inactive {
    background: #faec6e;
    border-radius: 50%;
  }
  & .deleted {

    &::after, &::before {
      content: '';
      width: 18px;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 0;
      background: red;
    }
    &::after {
      transform: translateY(-50%) rotate(45deg);
    }
    &::before {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}