.good {
  border: 2px solid #4C897A;
  transition: all 1s linear;
  margin-bottom: 20px;
}
.goods {
  max-height: 10000px;
  padding: 15px 5px;
  transition: .1s all linear;
  //overflow: hidden;
  opacity: 1;
}
.goodHide {
  max-height: 0;
  padding: 0 5px;
  overflow: hidden;
  opacity: 0;
}
.goodHide > div {
  height: 0;
  padding: 0;
  margin: 0;
}
.goodHeader {
  display: flex;
  justify-content: space-between;
  background: #4c897a;
  padding: 11px 37px;
}
.createOrderDropDown {
  background: #fff;
}
.dropDownListBody {
  background: #fff;
}
.goodName {
  min-height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding-left: 27px;
  position: relative;
  cursor: pointer;
}
.goodName:after {
  content: '';
  display: inline-block;
  width: 17px;
  height: 100%;
  background: url("../../../../assets/icons/arrow-bottom-white.svg") center no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .2s linear;
}
.goodNameActive:after {
  transform: rotate(-180deg);
}
.deleteGood {
  cursor: pointer;
  transition: all .3s linear;
}
.deleteGood:hover svg path {
  fill: #d7d7d7;
}
.addGood {
  padding: 5px 10px;
  background: #4C897A;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.32);
}
.addGood > img {
  width: 19px;
  height: 19px;
  margin-right: 5px;
}
.btnGoodName {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}
.blockTotal {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.total,
.totalSum {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  margin-right: 5px;
}
.totalSum {
  width: 219px;
  min-height: 29px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 7px;
  text-align: end;
  border-radius: 10px;
}
.totalWrap {
  margin-right: 5px;
}
.currency {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
}