.form-table{
	width: 100%;
	tr{
		height: 41px;
		position: relative;
	}
	tr + tr{
		margin: 5px 0;
	}
	th, td{
		height: inherit;
		padding: 5px 0;
	}
	th + th, td + td{
		padding: 5px 5px;
	}
	tr th p, tr td p, 
		.form-input-quantity, .form-input-quantity div,
			.form-input-quantity input{
				height: 100%;
				border-radius: 10px;
	}
	tr th p, tr td p{
		padding-top: 8px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #1E252B;
		padding-bottom: 8px;
	}
	tr th p{
		background: #A8D3C9;
	}
	.remove-order-item-string{
		position: absolute;
		padding: 2px 5px 5px 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.remove-order-item-string button{
		background-color: transparent;
		opacity: 0.3;
		height: 100%;
		&:hover{
			opacity: 1;
			color: black;
			transition: all 500ms;
		}
	}
}
.form-input input, .form-table td p{
	padding-left: 10px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #1E252B;
	background-color: #FFFFFF !important;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	height: 100%;
}
.form-input, .form-input input{
	height: 100%;
}
input:focus, textarea:focus{
	box-shadow: 0px 0px 5px 0px #a8d3c9 !important;
	background: #ffffff !important;
	transition-duration: 100ms;
}

.pNumber{
	text-align: center;
	min-width: 37px;
	padding-left: 0 !important;
}
.form-input-quantity input{
	min-width: 37px;
	padding-left: 0 !important;
}
.form-input, .form-input-quantity{
	min-width: 37px;
}
.form-input input, .form-input-quantity input{
	display: block;
	width: 100%;
}
input[type='number'] {
    -moz-appearance:textfield;
}

input[disabled] {
	color: #808080;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.form-input-quantity{
	position: relative;
	text-align: center;
	input{
		text-align: center;
	}
	button{
		position: absolute;
		top: 7px;
		font-size: 16px;
		background-color: #FFFFFF;
		&:nth-child(3){
			left: 10px;
		}
		&:nth-child(2){
			right:  10px;
		}
	}
}

.form-textarea{
	margin: 30px 0 50px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	textarea{
		width: 836px;
		height: 89px;
		background: #F9F9F9;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		resize: none;
		padding: 10px 20px;
		font-size: 12px;
		line-height: 15px;
	}
}
.form-input-document input{
	font-size: 12px;
	width: 850px;
	height: 31px;
	padding-left: 10px;
	margin: 0 10px 20px 10px;
}
.field--error {
	position: relative;
	input, textarea {
		border: 1px solid red !important;
	}
	.textAreaErr{
		color: red;
		font-size: 10px;
		position: absolute;
		border: red;
	}
}
.radioError + label:before {
	 border: 1px solid red !important;
 }
.date-of-implementation{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 20px;
	&__manufacture, &__delivery{
		flex: 0 0 50%;
		display: flex;
		align-items: center;
	}
	&__manufacture span, &__delivery span{
		margin-right: 10px;
	}
}
.order-data-picker{
	position: relative;
	button{
		position: absolute;
		right: -15px;
		background-color: transparent;
		top: 3px;
	}

	input {
		background: transparent;
	}
}
.Selectable--open{
	animation: open-picker 0.5s forwards ease-out;
}
@keyframes open-picker {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1 !important;
	}
  }
.Selectable{
	position: absolute;
	top: 30px;
	left: -30px;
	background: #ffffff;
	border: 1px solid #A8D3C9;
	z-index: 1;
}
.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #A8D3C9 !important;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .DayPicker-NavButton--prev {
    margin-right: 1.9em !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #4C897A;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #4C897A;
}


.date-picker-block{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 180px;
	height: 25px;
	cursor: pointer;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
	background-color: #FFFFFF !important;
	color: #1E252B;
	border-radius: 10px;
	position: relative;
	input{
		width: 90px;
		height: 25px;
		padding-left: 10px;
		cursor: pointer;
	}
}
.date-picker-error{
	border: 1px solid red !important;
	&__text{
		color: red;
		font-size: 10px;
		position: absolute;
		bottom: -15px;
	}
}