.buttons {
  padding-bottom: 20px;
}
.filter {
  position: relative;
}

.buttonFilter {
  min-width: 260px;
  height: 33px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background: #f0f0f0;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
  position: relative;
  z-index: 200;
}

.filterIcon {
  margin-right: 10px;
}

.listPoints {
  min-width: 260px;
  padding: 38px 9px 9px;
  border: 2px solid #F8F8F8;
  border-radius: 10px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  & > li {
    padding: 8px 10px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1E252B;

    &:hover {
      background: #f5f5f5;
      cursor: pointer;
    }
  }
}

.headers, .good {
  width: 630px;
  display: grid;
  grid-template-columns: 260px 1fr 1fr;
  gap: 10px;
}
.headers {
  margin-bottom: 10px;

  div {
    padding: 10px;
    text-align: center;
    background: #A8D3C9;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}
.good {
  margin-bottom: 10px;

  .goodField {
    padding: 10px;
    text-align: center;
    background: #F9F9F9;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    
    &:hover {
      cursor: help;
    }
  }
}
.emptyGoods {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1E252B;
}