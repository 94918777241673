.scheme {
  display: flex;
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
}
.fieldWrap {
  width: 160px;
  height: 135px;
  position: relative;

  &:not(:last-child) {
    margin-right: 55px;
  }
}
.inputWrap {
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.checkboxInput {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}
.schemeLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 30px;
  font-weight: 500;
  line-height: 37px;
  color: #1e252b;
  background: #fff;
  border: 4px solid transparent;
  border-radius: 10px;
}
.checkboxInput:checked + .schemeLabel {
  font-weight: 600;
  background: #f4f4f4;
  border: 4px solid #a8d3c9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}