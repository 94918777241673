.cell,
.editingField {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  padding: 7px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.editingField {
  border: 1px solid #d0e7e2;
}