.modalInfo {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(98, 98, 98, 0.5);
}
.modalContent {
  width: 521px;
  max-height: 85vh;
  background: #f9f9f9;
  border: 0.3px solid #76838f;
  border-radius: 10px;
  padding: 30px;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  overflow: auto;
}
.headerOrderBlock {
  position: relative;
  margin-bottom: 39px;
}
.headerOrderLink {
  width: 100%;
  display: inline-block;
  padding: 0px 100px;
  text-align: center;
}
.title {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1e252b;
  text-align: center;
}
.changeOrder {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: none;
  padding: 5px;
}
.closeModal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background: none;

  &:hover {
    cursor: pointer;
  }
}
.orderTitle {
  margin-right: 15px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1e252b;
}
.typeWrap {
  display: flex;
  align-items: center;
}
.type {
  align-self: flex-start;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #4C897A;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 15px;
}
.select {
  width: 50px;
  height: 25px;
  padding: 4px;
  border: 1px solid grey;
}
.time {
  margin-bottom: 31px;
  padding: 0 30px;
}
.date {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;

  &:not(:last-child) {
    margin-bottom: 25px;
  }
}
.goods {
  margin-bottom: 30px;
}
.good {
  margin-bottom: 30px;
}
.goodWrap {
  position: relative;
  padding: 60px 30px;

  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 12px solid #4C897A;
  }
}
.goodItem {
  margin-bottom: 20px;
}
.dateName,
.dateValue {
  min-width: 143px;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  margin-right: 16px;
}
.dateName {
  font-weight: 500;
}
.dateValue {
  font-weight: 400;
  border-bottom: 1px solid rgba(30, 37, 43, 0.42);
}
.goodHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.goodNum,
.goodName,
.goodQuantity {
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  padding: 7px 10px;
}
.goodNum {
  width: 37px;
  margin-right: 10px;
  text-align: center;
}
.goodName,
.goodInput {
  flex-grow: 1;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
.goodQuantity {
  width: 71px;
}
.goodHeader {
  .goodNum,
  .goodName,
  .goodQuantity {
    background: #A8D3C9;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-align: center;
  }
  .goodNum {
    font-weight: 500;
  }
  .goodName {
    font-weight: 500;
  }
  .goodQuantity {
    font-weight: 500;
  }
}
.goodContent {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.goodContent {
  .goodNum,
  .goodName,
  .goodQuantity {
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  .goodNum {
    font-weight: 400;
  }
  .goodName {
    font-weight: 400;
  }
  .goodQuantity {
    font-weight: 400;
  }
}
.goodContent .goodInput {
  height: auto;
}
.equipment {
  margin-bottom: 30px;
}
.equipmentWrap {
  margin-bottom: 20px;
}
.equipmentHeader {
  .equipmentMaterial,
  .equipmentName,
  .equipmentProvider {
    text-align: center;
  }
}
.equipmentHeader,
.equipmentContent {
  display: flex;
}
.equipmentMaterial,
.equipmentName,
.equipmentProvider {
  background: #A8D3C9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 7px 10px;
}
.equipmentMaterial {
  width: 190px;
  margin-right: 10px;
}
.equipmentName {
  flex-grow: 1;
  margin-right: 10px;
}
.equipmentProvider {
  width: 180px;
}
.equipmentHeader {
  margin-bottom: 10px;
}
.equipmentContent {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .equipmentMaterial,
  .equipmentName,
  .equipmentProvider {
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1E252B;
  }

  //.goodInput {
  //  height: auto;
  //}
}

.note {
  margin-bottom: 40px;
}
.noteWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.tks {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.tk {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.tkHeader,
.tkValue {
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 7px 10px;
}
.tkHeader {
  font-weight: 500;
  text-align: center;
  min-width: 120px;
  background: #A8D3C9;
  margin-right: 10px;
}
.tk .tkValue {
  font-weight: 400;
  flex-grow: 1;
  background: #fff;

  & input {
    border: none;
  }
}
.fieldNote,
.fieldNoteArea {
  width: 100%;
  height: 81px;
  background: #fff;
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
  padding: 7px 10px;
  font-weight: 400;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.fieldNoteArea {
  padding: 0;
  line-height: 0;

  & > textarea {
    width: 100%;
    height: 100%;
    padding: 7px 10px;
    border-radius: 10px;
    resize: none;
  }
}

.scheme {
  display: flex;
}
.schemeName {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #1e252b;
  margin-right: 16px;
}
.schemeValue {
  min-width: 218px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
  border-bottom: 1px solid rgba(30, 37, 43, 0.42);
}

// стилизация инпутов для редактирования
.goodInputProduct,
.goodInputQuantity {
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, .25%);
  border-radius: 10px;

  & > input {
    width: 100%;
    height: auto;
    padding: 7px 10px;
    border: none;
    font-weight: 400;
  }
}
.goodInputProduct {
  flex-grow: 1;
  margin-right: 10px;
}
.goodInputQuantity {
  width: 71px;
}

.wrapInput {
  position: relative;
  flex-grow: 1;
}
.wrapInput {
  .productSearchBar {
    display: none;
    flex-direction: column;
    border: 1px solid #76838F;
    border-radius: 10px;
    background: #fff;
    padding: 10px;
    position: absolute;
    top: 125%;
    left: 0;
    z-index: 1;
  }

  .productSearchBarActive {
    display: flex;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}
.materialName {
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #76838F;

  &:hover {
    cursor: pointer;
    background: #b3b6b8;
  }
}