.flexBlock {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.infoPaymentTitle {
  margin-right: 15px;
}
.inputRadio {
  position: relative;
  padding-left: 20px;
  &:not(:last-child) {
    margin-right: 25px;
  }

  & input[type="radio"] {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: unset;
    opacity: 0;
    visibility: hidden;
  }
  & input[type="radio"] + label:before {
    border: 1px solid #A8D3C9;
  }
  & input[type="radio"] + label:before, input[type="radio"] + label:after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  & input[type="radio"]:checked + label:after {
    left: 3px;
    width: 10px;
    height: 10px;
    background: #4C897A;
  }

  &:hover,
  & > input,
  & > label {
    cursor: pointer;
  }
}
.atRegistrationInput {
  margin-right: 10px;
}
.atRegistrationInput, .atRegistrationInput input{
  height: 23px;
}
.atRegistrationInput input{
  padding: 7px 10px;
}
.formItemBlock {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 30px;
  margin: 60px 0 50px;
}
