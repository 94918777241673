.personalData {
  background: rgba(255, 255, 255, 0.61);
  border: 0.3px solid #76838F;
  border-radius: 10px;
  padding: 30px;
}
.personalContent {
}
.person {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(168, 211, 201, 0.28);
}
.personalImg {
  width: 50px;
  height: 50px;
  background: #798489;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
}
.personalImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.personalName {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
}
.data {
  margin-top: 25px;
}
.dataItem {
  display: flex;
  justify-content: space-between;
  align-items: center;


  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.phoneTitle {
  width: 42%;
  font-weight: 500;


  @media only screen and (max-width: 575px) {
    margin-bottom: 10px;
  }
}
.phone {
  width: 58%;
  font-weight: 400;
  padding: 7px 10px;
  background: #F9F9F9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
}
.phone,
.phoneTitle {
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
}