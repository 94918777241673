.blockLegsMoldings {
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 25px;
}
.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1e252b;
  margin-bottom: 20px;
}
.blockForm {
  display: flex;
  margin-bottom: 10px;
}
.blockInputs {
}
.formHeader {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  padding: 7px 10px;
}
.formHeader,
.inputFormName {
  margin: 0 10px 0 0;

  &:last-child {
    margin-right: 0;
  }
}
.formHeaderMaterial,
.blockForm > span:nth-child(1) {
  width: 263px;
}
.formHeaderName,
.blockForm div {
  width: 263px;
}
.formHeader {
  background: #a8d3c9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.inputFormName {
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
}
.blockForm input:not([type=checkbox]) {
  padding: 7px 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}
.blockForm > div > input {
  padding: 7px 10px !important;
}
.wrapInput {
  position: relative;
}
.wrapInput {
  .productSearchBar {
    max-height: 300px;
    display: none;
    flex-direction: column;
    border: 1px solid #76838F;
    border-radius: 10px;
    background: #fff;
    padding: 10px;
    position: absolute;
    top: 125%;
    left: 0;
    overflow: auto;
    z-index: 1;
  }

  .productSearchBarActive {
    display: flex;
  }
}
.materialName {
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #76838F;

  &:hover {
    cursor: pointer;
    background: #b3b6b8;
  }
}
