.performers {
  margin: 39px 0 0;
}

.nav {
  display: flex;
  justify-content: space-between;
}

.btnAddUser {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
  padding: 4px 9px;
  background: #e1e1e1;
  border-radius: 5px;
  margin-bottom: 28px;
}

.modal {
  width: 100%;
  height: 100%;
  background: rgba(98, 98, 98, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}

.tabsWrap {
  display: flex;
}

.filterWrap {
  position: relative;
}

.filter {
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  .arrowTop, .arrowBottom {
    transition: .3s all ease-in-out;
  }

  .arrowTop {
    transform: rotate(0);

    &.active {
      transform: rotate(180deg);
    }
  }

  .arrowBottom {
    transform: rotate(180deg);

    &.active {
      transform: rotate(360deg);
    }
  }
}

.filterList {
  min-width: 166px;
  min-height: 38px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #b0b0b0;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  top: 26px;
  left: 0;
  z-index: 20;
}

.filterItem {
  padding: 5px 5px 5px 26px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  &.activeFilter {
    background: #f5f5f5;
  }

  & .all, .active, .inactive, .deleted {
    display: inline-block;
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
  }
  & .all {
    border: 2px solid #4c897a;
    border-radius: 50%;

    &:after {
      width: 10px;
      height: 10px;
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #4c897a;
      border-radius: 50%;
    }
  }
  & .active {
    background: #4c897a;
    border-radius: 50%;
  }
  & .inactive {
    background: #faec6e;
    border-radius: 50%;
  }
  & .deleted {

    &::after, &::before {
      content: '';
      width: 18px;
      height: 3px;
      position: absolute;
      left: 0;
      background: red;
      transform: rotate(45deg);
    }
    &::after {
      top: 50%;
      transform: rotate(45deg);
    }
    &::before {
      top: 50%;
      transform: rotate(-45deg);
    }
  }
}