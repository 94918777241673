.table {
  width: 580px;
  display: grid;
}

.header,
.content {
  display: grid;
  grid-template-rows: 30px;
}

.header {
  grid-template-columns: 30px 300px 250px;

  & > .headerCell {
    justify-content: center;
    background: #bababa;
  }
}

.content {
  & .contentCell {
    justify-content: flex-start;
    background: #fff;
    position: relative;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  }
}

.headerCell,
.contentCell {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.headerCell {

}

.row {
  display: grid;
  grid-template-columns: 30px 300px 250px;
  grid-template-rows: 30px;
}

.points {
  width: 250px;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: absolute;
  top: 30px;
  left: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.62);
  z-index: 10;
}

.pointItem {
  width: 100%;
  padding: 7px 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

  &:hover {
    background: #f4f4f4;
    cursor: pointer;
  }
}

.dropdownCell {
  &:hover {
    cursor: pointer;
  }
}