$placeholder-gray: #1E252B;

.field {
  position: relative;

  & > input:not([type='radio']):not([type=checkbox]) {
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $placeholder-gray;
    padding: 11px 20px;
    border: 1px solid transparent;
    border-radius: 10px;
    background: #fff;

    &:not([type=checkbox]) {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
      -webkit-appearance: none;
    }

    &:focus {
      border: 1px solid #4C897A;
      outline: #4C897A;
    }

    &:disabled {
      background: #eaeaea;
    }
  }

  & > textarea {
    border: 1px solid transparent;
  }
}
.fieldError > input:not([type='radio']):not([type=checkbox]),
.fieldError > input:focus:not([type='radio']):not([type=checkbox]),
.fieldError > textarea,
.fieldError > textarea:focus {
  border: 1px solid red;
}
.error {
  position: absolute;
  bottom: -18px;
  left: 0;
  color: red;
}