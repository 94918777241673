
.buttons {
  margin-bottom: 30px;
}

.storageAddMaterials {
  max-width: 174px;
  background: #4c897a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px 13px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #fff;
}

.content {
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
}

.header,
.data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

//.headerTitleEmptyBgColor,
.headerTitleModal,
.headerTitleName,
.headerTitleUnit,
.headerTitleQuantity,
.headerTitleOrder {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #1e252b;
  padding: 6px;
  background: #a8d3c9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
.headerTitleModal {
  &:not(:last-child) {
    margin-right: 10px;
  }
}
//.headerTitleEmptyBgColor,
//.dataItemCheckbox {
//  width: 37px;
//  height: 31px;
//  margin-right: 10px;
//}
//.headerTitleEmptyBgColor {
//  position: relative;
//  padding: 0;
//}
.markAllButtonLabel {
  width: 100%;
  height: 100%;
  display: inline-block;
  cursor: pointer;
  transition: none;
}
.markAllButton {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;

  &:checked + .markAllButtonLabel {
    background: no-repeat center / 60%  url("../../../../assets/icons/checked.svg");
  }
}

.data .dataItem {
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  display: flex;
  align-items: center;
  padding: 6px 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
.headerTitleName,
.dataItemName {
  flex-grow: 1;
  min-height: 31px;
  margin-right: 10px;
}
.dataModalItemName {
  width: 300px !important;
}
.headerTitleUnit,
.data .dataItemUnit {
  width: 74px !important;
  min-height: 31px;
  margin-right: 10px;
  flex-shrink: 0;
}

.headerTitleQuantity,
.data .dataItemQuantity {
  width: 196px !important;
  min-height: 31px;
  margin-right: 10px;
  flex-shrink: 0;
}

.headerTitleOrder,
.data .dataItemOrder {
  width: 196px !important;
  min-height: 31px;
  margin-right: 0;
  flex-shrink: 0;
}

//.headerTitleEmpty, --------------- удалить
.dataItemButtons {
  width: 37px;
  height: 31px;
  background: transparent;
  border: none;
  box-shadow: none;
  flex-shrink: 0;
}

.buttonItem {
  width: 100%;
  height: 100%;
  background: url("../../../../assets/icons/plus-green.svg") center no-repeat;
}
.inputWrap {
  width: 37px;
  height: 31px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
}
.inputIsChecked {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;

  &:checked + .itemLabel {
    background: no-repeat center / 60%  url("../../../../assets/icons/checked.svg");
  }
}
.itemLabel {
  width: 100%;
  height: 100%;
  display: inline-block;
  cursor: pointer;
  transition: none;
}



// модалка для пополнения
.modalMat {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: rgba(98, 98, 98, 0.5);
  z-index: 1;
}

.renewalModal,
.renewalModalConsumablies {
  min-height: 150px;
  max-height: 75vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  background: #f9f9f9;
  border: 0.3px solid #76838f;
  border-radius: 10px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f9f9fd;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a8d3c933;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f9f9fd;
  }
}
.renewalModalConsumablies {
  min-width: 860px;

  .data .dataItem {
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1e252b;
    display: flex;
    align-items: center;
    padding: 6px 10px !important;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .headerTitleName,
  .dataItemName {
    width: 320px !important;
    min-height: 31px;
  }
  .dataModalItemName {
    width: 300px !important;
  }
  .headerTitleUnit,
  .data .dataItemUnit {
    width: 74px !important;
    min-height: 31px;
  }

  .headerTitleQuantity,
  .data .dataItemQuantity {
    width: 196px !important;
    min-height: 31px;
  }

  .headerTitleOrder,
  .data .dataItemOrder {
    width: 196px !important;
    min-height: 31px;
    margin-right: 0 !important;
  }
}
.modalTitle {
  width: 350px;
  text-align: center;
  margin: 0 auto 25px;
}

.formData {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.dataName {
  margin-right: 15px;
}

.wrapButtons {
  display: flex;
  justify-content: flex-end;
}

.buttonSend {
  padding: 7px 34px;
  background: #4c897a;
  border: 0.1px solid #76838f;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}
.fieldRenewal {
  padding: 7px 10px;
  height: 31px;
}
.closeButton {
  width: 35px;
  height: 35px;
  color: #76838f;
  border: none;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  background: url("../../../../assets/icons/cross.png") no-repeat center;
}

// Модалка с множественным пополнением
.renewalModalConsumables {
  width: 420px;
  min-height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  background: #f9f9f9;
  border: 0.3px solid #76838f;
  border-radius: 10px;
}