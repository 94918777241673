.modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: rgba(98, 98, 98, 0.5);
	opacity: 0;
	animation: modal-show 0.5s 1 forwards;
	z-index: 1000;
	@keyframes modal-show {
		0% { opacity: 0; }
		100% { opacity: 1; }
	}
	&_show {
		display: flex;
	}
	&_content {
		width: 631px;
		height: 80%;
		overflow: auto;
		position: fixed;
		padding: 24px;
		background: #FFFFFF;
		border: 0.3px solid #76838F;
		box-sizing: border-box;
		border-radius: 10px;
		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
		&::-webkit-scrollbar {
			width: 10px;
			background-color: #f9f9fd;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb{
			border-radius: 10px;
			background-color: #a8d3c933;
		}
		&::-webkit-scrollbar-track{
			border-radius: 10px;
			background-color: #f9f9fd;
		}
	}
}