.order-creation{
	margin: 50px 0;
}
.button-custom{
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #FFFFFF;
	background: #4C897A;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 17px;
	box-shadow: 0px 3px 3px 0px rgba(8, 8, 8, 0.3);
	margin-bottom: 30px;
	span{
		display: inline-block;
		margin-right: 10px;
		font-size: 34px;
		line-height: 0;
		font-weight: 400;
	}
	&:hover{
		background: rgba($color: #4C897A, $alpha: 0.9);
	}
	&:disabled{
		background: #A8D3C9;
		pointer-events: none;
	}
}
.add-string__btn{
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: rgba(30, 37, 43, 0.48);
	background: transparent;
	box-shadow: none;
	margin-top: 25px;
	margin-bottom: 20px;
	width: auto;
	height: auto;
	span {
		color: rgba(76, 137, 122, 1);
		font-size: 25px;
	}
	&:hover{
		background: transparent;
		color: rgba(0, 0, 0, 1);
	}
	&:disabled {
		background: transparent;
		pointer-events: none;
	}
}
.save-order__btn{
	width: 230px;
	height: 45px;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #1E252B;
}
.add-document__btn{
	color: #1E252B;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	width: 165px;
	height: 36px;
	padding: 0;
	margin-bottom: 20px;
}