.sendForm {
  max-width: 340px;
  min-height: 83px;
  position: fixed;
  top: 100px;
  right: 40px;
  display: inline-block;
  background: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #1E252B;
  padding: 12px 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 100;

  .sendFormTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #1E252B;
    margin-bottom: 5px;
  }
  .sendFormText {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #37474F;
  }
  .closeDownload {
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }
  }
}
// стилизация блока с кнопками для создания договоров
.buttonsOrderCreate {
  display: flex;
}
.buttonCreate {
  margin-right: 30px;
}
// Модальное окно создания заказа
.modal {
  width: 1000px;
}



