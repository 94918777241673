.history {
  padding-top: 14px;
}

.table {
  width: 953px;
  display: grid;
  grid-gap: 10px;
}

.header, .contentRow {
  display: grid;
  grid-template-columns: 123px 144px 206px 220px 220px;
  grid-gap: 10px;
  grid-row-gap: 10px;
}

.header div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #A8D3C9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.content {
  display: grid;
  grid-gap: 10px;
}

.contentRow .contentCell {
  padding: 10px 15px;
  background: #F9F9F9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.contentCell, .contentCell > a {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
}