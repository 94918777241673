.modalOrders {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(98, 98, 98, 0.5);
  opacity: 0;
  animation: modal-show 0.5s 1 forwards;
  z-index: 1000;
}

.modalContent {
  max-width: 500px;
  position: relative;
  padding: 30px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;

  & p {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 25px;
    color: #1E252B;
  }

  &_subTitle {
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: rgba(0, 0, 0, 0.56) !important;
  }
}

.modalShow {
  display: flex;
}

@keyframes modal-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalControl {
  display: flex;
  justify-content: center;

  & button {
    width: 165px;
    height: 36px;
    background: #E6F1EF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: rgba(30, 37, 43, 0.88);

    &:first-child {
      margin-right: 30px;
    }

    &:last-child {
      background: #A8D3C9;
    }

    &:hover {
      box-shadow: inset 0px 3px 4px rgba(0, 0, 0, 0.25);
    }
  }
}