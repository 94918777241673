.btnsBlock {
  display: flex;
  justify-content: center;
}
.sendOrderBtn {
  margin-right: 15px;
  margin-bottom: 0;
  padding: 10px 17px;
}
.saveOrderBtn {
  min-width: 71px;
  margin-bottom: 0;
}
.floppyBtn {
  border: none;
  background: none;
  margin-left: 25px;
}
.agreementName {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-right: 10px;
  }
}
.agreementInput {
  padding: 5px !important;
}
.agreementWrap {
  font-size: 14px;
}
.closeCreateOrder {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background: none;

  &:hover {
    cursor: pointer;
  }
}