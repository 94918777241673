.table {
  width: 450px;
  display: grid;
  grid-template-areas: 'head'
                       'content';
  grid-template-rows: 31px 1fr;
}

.head {
  grid-area: head;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.headCell,
.contentId,
.footerCell {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  padding: 7px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.headCell {
  background: #a8d3c9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  justify-content: center;

  &:first-child {
    border-radius: 7px 0 0 0;
  }

  &:last-child {
    border-radius: 0 7px 0 0;
  }
}

.contentItem,
.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 31px;
}

.contentId {
  background: #fff;
  position: relative;

  & .active, .inactive, .deleted {
    display: inline-block;
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(-50%);
  }
  & .active {
    background: #4c897a;
    border-radius: 50%;
  }
  & .inactive {
    background: #faec6e;
    border-radius: 50%;
  }
  & .deleted {

    &::after, &::before {
      content: '';
      width: 18px;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 0;
      background: red;
    }
    &::after {
      transform: translateY(-50%) rotate(45deg);
    }
    &::before {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

.footerCell {
  &:first-child {
    background: #a8d3c9;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 0 7px;
  }

  &:last-child {
    border-radius: 0 0 7px 0;
  }
}

.contentId {
  padding-left: 34px;
}

.content {
  grid-area: content;
  display: grid;
}

.modal {
  display: flex;
  width: 500px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: green;
}