.formItemBlock {
  padding: 30px 20px 10px 20px;
  background: #F9F9F9;
  border-radius: 10px;
  margin-bottom: 50px;
}
.formItemTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1E252B;
  margin-bottom: 20px;
}
.productSearch {
  position: relative;
}
.productSearchBar {
  display: none;
  width: max-content;
  max-height: 420px;
  position: absolute;
  left: 5px;
  top: 100%;
  border: 1px solid #76838F;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
  overflow-y: auto;
  z-index: 1;
}
.productSearchBarActive {
  display: flex;
  flex-direction: column;
}
.productName {
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #76838F;

  &:hover {
    background: #b3b6b8;
  }
}