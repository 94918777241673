.orderingMaterials{
	padding: 0 150px;
	&_mainTitle{
		margin-bottom: 50px;
	}
	&_content{
		padding: 50px;
		background: #FFFFFF;
		border: 0.3px solid #76838F;
		border-radius: 10px;
		width: 1055px;
		margin: 0 auto;
	}
	.menu {
		margin-bottom: 30px;
		border-bottom: 1px solid #76838F;
	}
	.tabs {
		display: flex;
	}
	.tabsItem {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: #76838F;
		padding-bottom: 16px;
		&:not(:last-child) {
		  margin-right: 10px;
		}
	}
	.tabsItemActive {
		color: #4C897A;
		position: relative;
		&:after {
		  content: '';
		  width: 100%;
		  height: 3px;
		  position: absolute;
		  left: 0;
		  bottom: -2px;
		  background: #4C897A;
		}
	}
}
.materialInput{
	input {
		height: 31px !important;
	}
}
// ------------------------------orders------------------------
.orders{
	display: flex;
	justify-content: space-between;
	margin-bottom: 200px;
	&_requiresToOrder, &_awaitingReceipt{
		flex: 0 0 437px;
	}
	&_archive{
		display: flex;
		justify-content: space-between;
		margin-bottom: 200px;
		flex-wrap: wrap;
	}
}
.ordersItem{
	&_title{
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #2F3B3E;
		text-align: center;
		margin-bottom: 25px;
	}
	&_card{
		background: #F9F9F9;
		border: 0.3px solid #76838F;
		border-radius: 10px;
		padding: 30px;
		margin-bottom: 30px;
		position: relative;
	}
}
.card{
	&_changeItem{
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		background: transparent;
		position: absolute;
		top: 30px;
		right: 30px;
		cursor: pointer;
		&:hover{
			color: #517690;
		}
	}
	&_title{
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		margin-bottom: 10px;
	}
	&_info {
		font-weight: 900;
		font-size: 13px;
		line-height: 16px;
		text-align: center;
		margin: 0 auto;
		color: #0096ff;
		text-decoration: underline;
		background: none;
	}
	&_item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:not(:last-child){
			margin-bottom: 10px;
		}
		
		&_date{
			font-weight: normal;
			font-size: 14px;
			line-height: 17px;
			color: #1E252B;
			margin-top: 30px;
			span{
				margin-left: 30px;
			}
		}
		&_title{
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
		}
		&_value{
			width: 218px;
			height: 31px;
			background: #FFFFFF;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			padding: 7px 10px 7px 10px;
		}
		&_notes{
			width: 377px;
			height: 69px;
			background: #FFFFFF;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			resize: none;
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;
			color: rgba(30, 37, 43, 0.42);
			padding: 10px 20px;
			margin: 30px 0;
		
		}
		&_divDate{
			width: 180px;
			margin-right: 37px;
		}
		&_datePicker{
			background: #FFFFFF;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			height: 31px;
			padding-top: 2px;
			position: relative;
			border: none;
			width: 182px;
			margin-right: 37px;
			& div {
				box-shadow: none;
			}
		}
		&_access{
			border: 1px solid #a8d3c9;
		}
		&_amountBlock{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 218px;
			//& div:first-child{
			//	width: 85px;
			//}
			//& div:last-child{
			//	width: 85px;
			//}
			div:not(:last-child){
				margin-right: 10px;
			}
			input{
				width: 85px;
				height: 31px;
				background: #FFFFFF;
				box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
				border-radius: 10px;
				padding: 0 10px;
				border: 1px solid #a8d3c9;
				&:disabled{
					border: none;
				}
			}
		}
		textarea {
			width: 377px;
			height: 69px;
			background: #FFFFFF;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			resize: none;
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;
			color: rgba(30, 37, 43, 0.42);
			padding: 10px 20px;
			margin: 30px 0 0 0;
			border: 1px solid #a8d3c9;
			&:disabled{
				border: none;
			}
		}
		
	}
}
.item_control{
	margin-top: 30px;
	align-items: flex-end;
	span{
		font-weight: normal;
		font-size: 9px;
		line-height: 11px;
		span{
			margin-left: 5px;
		}
	}
	&_button{
		width: 165px !important;
		height: 36px !important;
		padding: 4px !important;
		margin-bottom: 0 !important;
		font-size: 16px !important;
		line-height: 20px !important;
	}
}
.awaitingReceipt_button{
	width: 165px !important;
}
.orderMaterial_modal{
	padding: 30px;
	width: 437px;
	background: #F9F9F9;
}
.ModalContent{
	&_title{
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #1E252B;
		text-align: center;
		margin-bottom: 25px;
	}
	& .ordersItem_card{
		border: none;
		padding: 0;
		margin: 0;
	}
	&_bells{
		position: absolute;
		top: 0px;
		right: -40px;
	}
}
.closeForm {
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 10px;
	background: none;

	&:hover {
		cursor: pointer;
	}
}
.modal_submit{
	width: 165px;
	height: 36px;
	background: #4C897A;
	border: 0.1px solid #76838F;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #FFFFFF;
	float: right;
	margin-top: 30px;
}
.orderName {
	margin-bottom: 25px;
	text-align: center;
}
.orderModal{
	width: 648px;
	padding: 30px 0 !important;
	color: #1E252B;
	background: #F9F9F9;
	overflow-y: scroll;
	max-height: 95vh;
}