@import './src/style/variable';

// стилизация шапки таблицы
.header {
  display: grid;
  grid-template-columns: 88px repeat(9, 1fr);
  position: sticky;
  top: $header-height;
  z-index: 50;

  & > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #1E252B;
    text-align: center;
    padding: 7px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    background: #c4e6de;
    border: 1px solid rgba(98, 109, 119, 0.58);

    &:first-child {
      border-radius: 10px 0 0 0;
    }
    &:last-child {
      border-radius: 0 10px 0 0;
    }
  }
}

.filterName {
  position: relative;
  display: flex;
  width: 300px;
}

.searchBlock {
  margin-bottom: 30px;
}
.searchInput {
  width: 100%;
  height: 38px;
  padding: 5px 12px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;

  &:after {
    display: flex;
    width: 20px;
    height: 20px;
    background: red;
    content: 'X';
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
  }
}

.close {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }
}