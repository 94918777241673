@import "src/style/variable";

// Стилизация блока поиска заказов
.title {
  width: 200px;
  padding-right: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1E252B;
}
.headerList {
  width: 1075px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
}
.search {
  display: flex;
}
.searchInput {
  width: 300px;
  height: 38px;
  padding: 5px 12px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;

  &:after {
    display: flex;
    width: 20px;
    height: 20px;
    background: red;
    content: 'X';
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
  }
}
.searchDate {
  position: relative;
}
.close {
  position: absolute;
  top: 50%;
  .close {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }
  }
  right: 10px;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }
}
.searchFall {
  padding: 20px 10px;
  font-size: 18px;
}
.listStatuses {

}

// стилизация таблицы
.table {
  display: grid;
  grid-template-areas: "header"
                       "content";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &Header {
    grid-area: header;
    display: grid;
    grid-template-areas: "numbers dateCreate status sum prepayment payment compiler dateDelivery buttonsOrder";
    grid-template-columns: 100px 129px 111px 111px 162px 162px 135px 124px 40px;
    grid-template-rows: 37px;
    position: sticky;
    top: $header-height;
    z-index: 300;

    & > div:first-child {
      border-radius: 10px 0 0 0;
    }
    & > div:last-child {
      border-radius: 0 10px 0 0;
    }
  }
  &Content {
    grid-area: content;
    display: grid;
  }
  &Row {
    display: grid;
    grid-template-areas: "numbers dateCreate status sum prepayment payment compiler dateDelivery buttonsOrder";
    grid-template-columns: 100px 129px 111px 111px 162px 162px 135px 124px 40px;
    grid-template-rows: 54px;
  }
}
.numbers,
.dateCreate,
.status,
.sum,
.prepayment,
.payment,
.compiler,
.dateDelivery,
.buttonsOrder {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: 8px;

  & > span {
    min-width: 0;
    max-height: 100%;
    display: inline-block;
  }
}
.numbers,
.dateCreate,
.compiler {
  &:hover {
    cursor: help;
  }
}

.numbersHeader,
.dateCreateHeader,
.statusHeader,
.sumHeader,
.prepaymentHeader,
.paymentHeader,
.compilerHeader,
.dateDeliveryHeader,
.buttonsOrderHeader {
  background: #a8d3c9;
  justify-content: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

  &:hover {
    cursor: default;
  }
}
.buttonsOrderHeader {
  position: relative;

  & > span {
    display: flex;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #1E252B;
    transition: .1s all;
    position: relative;
    background: rgba(26, 31, 36, 0.87);

    &::before, &::after {
      width: 5px;
      height: 5px;
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(26, 31, 36, 0.87);
      border-radius: 50%;
    }

    &::after {
      top: 7px
    }
    &::before {
      top: -7px
    }
  }
}
.numbers {
  grid-area: numbers;
}
.dateCreate {
  grid-area: dateCreate;
}
.status {
  grid-area: status;
}
.sum {
  grid-area: sum;
}
.prepayment {
  grid-area: prepayment;
}
.payment {
  grid-area: payment;
}
.compiler {
  grid-area: compiler;
}
.dateDelivery {
  grid-area: dateDelivery;
}
.showOrder {
  grid-area: showOrder;
  margin-bottom: 5px;
}
.sendOrder {
  grid-area: sendOrder;
}
.orderLink {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #000;
  padding: 5px;
  border-radius: 10px;
  background: none;

  &:hover {
    background: #F5F5F5;
  }
}
.buttonsOrder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.buttonsBlock {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 200;
}
.buttonsShow {
  width: 100%;
  height: 100%;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    display: flex;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgba(138, 148, 162, 0.9);
    transition: .1s all;
    position: relative;

    &:before, &:after {
      width: 5px;
      height: 5px;
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(138, 148, 162, 0.9);
      border-radius: 50%;
    }

    &:after {
      top: 7px
    }
    &:before {
      top: -7px
    }
  }

  &:hover {
    cursor: pointer;
    color: rgba(138, 148, 162, 0.9);

    & > span, & > span::after, & > span::before {
      background: rgba(138, 148, 162, 0.4);
    }
  }
}
.buttonsModal {
  min-width: 180px;
  position: absolute;
  content: '';
  top: 50%;
  right: 20px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 6px 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  z-index: 150;
}
//-----

.filterStatus {
  position: relative;

  &:not(:last-child) {
    margin-right: 20px;
  }
}
.filterStatusButton {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #8A94A2;
  width: 200px;
  height: 38px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  & > svg {
    margin-right: 12px;
  }
}
.filterListStatuses {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #8A94A2;
  width: 200px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0 10px 10px;
  padding: 10px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 400;

  & > li {
    padding: 5px;

    &:hover {
      background: #F5F5F5;
      cursor: pointer;
    }
  }
}