.orderDates {
  padding: 0 150px 50px;

  @media only screen and (max-width: 992px) {
    padding: 0 50px;
  }
}

.orderDatesTitle {
  margin-bottom: 50px;
}

.table {
  width: 630px;
  display: grid;
  grid-template-areas: 'tableHeader'
                       'contentTable';
}

.tableHeader {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.headerItem {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1E252B;
  padding: 10px 20px;
  background: #bee7dd;
  box-shadow: 0 0 1.87493px rgba(0, 0, 0, 0.25);

  &:first-child {
    border-radius: 15px 0 0 0;
  }
  &:last-child {
    border-radius: 0 15px 0 0;
  }
}

.contentTable {
  display: grid;
}

.contentRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contentItem {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1E252B;
  padding: 10px 20px;
  background: #fff;
  box-shadow: 0 0 1.87493px rgba(0, 0, 0, 0.25);
}