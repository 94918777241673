
.file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.btnFile {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  padding: 5px 12px;
  margin-bottom: 16px;
  background: #fafafa;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;
  z-index: 5;

  &:hover {
    cursor: pointer;
  }
}

.btnFileInput {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}

.fileName {
  display: flex;
  align-items: center;
  word-break: break-all;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1b609b;

  & a {
    color: #1b609b;
  }
}

.iconPdf {
  margin-right: 8px;
}

.deleteBtn {
  margin-left: 9px;

  &:hover {
    cursor: pointer;
  }
}