.prepaymentCell {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
}

.factSpan {
  width: 100%;
  height: 100%;
  display: block;
}

.factPrepayment {
  width: 50%;
}

.prepayment {
  width: 50%;
}
.fullWidthPrepayment {
  width: 100%;
}

.prepaymentInput {
  width: 100%;
  border-bottom: 1px solid #000;
}

.invalidValue {
  border-bottom: 1px solid #e00;
}