.authorize {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #80BED6 3.93%, rgba(200, 227, 221, 0.611474) 34.38%, rgba(216, 236, 231, 0.344362) 60.94%, rgba(237, 247, 245, 0) 87.5%);;
  overflow: hidden;
}
.authorizeForm{
  background: red;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.modal {
  width: 80%;
  max-width: 400px;
  max-height: 80%;
  padding: 30px 20px;
  background: #fff;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 10;
  position: relative;
  overflow: auto;

  @media (min-width: 768px) {
    width: 450px;
    padding: 50px;
  }
}
.logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.modalTitle {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #1E252B;
  margin-bottom: 35px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
}
.restoreBtn {
  background: none;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #1E252B;
  padding: 0 9px 5px;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #4C897A;
  }

  &:hover {
    color: #4C897A;

    &:after {
      background: #4C897A;
    }
  }
}
.restoreMessage {
  font-size: 18px;
  text-align: center;
  color: green;
  margin-bottom: 30px;
}
.modalCheckbox {
  display: flex;
  align-self: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #1E252B;
  cursor: pointer;
  margin-bottom: 30px;
}
.input {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.modalCheckbox input[type='checkbox'] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.wrongData {
  width: 100%;
  color: red;
  position: relative;
  top: -20px;
}
.recaptcha {
  margin-bottom: 5px;
}
.recaptchaError {
  color: red;
  margin-bottom: 10px;
}
.restore {
  padding: 30px 0;
}
.formRestore {
  margin-bottom: 30px;
}
.emailTitle {
  margin-bottom: 10px;
}
.inputWrap {
  margin-bottom: 22px;
  position: relative;
}
.fieldEmail span {
  font-size: 10px;
}
.fieldPass {
  position: relative;
}
.passwordControl {
  position: absolute;
  top: 11px;
  right: 15px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/icons/view.svg) 0 0 no-repeat;
}
.passwordControl.view {
  background: url(../../assets/icons/no-view.svg) 0 0 no-repeat;
}