.drop-down-list{
	margin-top: 20px;
	padding-left: 2px;
	&__header{
		display: flex;
		align-items: flex-start;
		width: fit-content;
		cursor: pointer;
		transition: all 500ms;
		margin-left: 32px;
		&--arrow{
			width: 13px;
			height: 13px;
			border: 3px solid #4C897A;
			transform: rotate(225deg);
			
			border-right: transparent;
			border-bottom: transparent;
			margin-right: 10px;
			transition: all 800ms;
		}
		&--title{
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			color: #1E252B;
			&:hover{
				color: rgba($color: #1E252B, $alpha: 0.7);
			}
		}
	}
	&__header-open{
		align-items: center;
		transition: all 500ms;
	}
	&__body{
		border-radius: 10px;
		height: auto;
		padding-top: 25px;
		max-height: 0;
		overflow: hidden;
		transition: all 500ms ease-out;
		opacity: 0;
		&--active {
			max-height: 10000px;
			margin-bottom: 30px;
			opacity: 1;
			transition: all 500ms ease-in;
		}
	}
}
.create-order-drop-down .drop-down-list__header{
	padding-left: 30px;
}
.create-order-drop-down .drop-down-list__body{
	background: #FFFFFF;
}
.create-order-drop-down .drop-down-list__body--active{
	background: #F9F9F9;
	padding-top: 0px;
    margin-top: 30px;
}
.create-order-drop-down .drop-down-list__body .form-item-block{
	margin-bottom: 0;
}
.arrow-open{
	transform: rotate(45deg);
}
.drop-down-list__body .form-item-block .remove-order-item-string{
	animation: close-remove 0.5s forwards ease-out;
	display: none;
}
.drop-down-list__body--active .form-item-block .remove-order-item-string{
	animation: open-remove 0.5s forwards ease-out;
	display: block;
}
@keyframes close-remove {
	0% {
	  opacity: 1;
	  display: block;
	}
	100% {
	  opacity: 0;
	  display: none;
	}
}
@keyframes open-remove {
	0% {
	  opacity: 0;
	  display: none;
	}
	100% {
	  opacity: 1;
	  display: block;
	}
}