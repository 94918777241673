.inputsBlock {
  display: flex;
  justify-content: space-between;
}
.headlines {
  display: flex;
  justify-content: space-between;
}
.headMaterial,
.headName,
.headProvider,
.headPrice {
  background: #a8d3c9;
  padding: 7px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.headMaterial,
.headName,
.headProvider,
.headPrice,
.inputMaterial,
.inputName,
.inputProvider,
.inputPrice {
  margin-bottom: 10px;
}
.inputMaterial > input,
.inputName > input,
.inputProvider > input,
.inputPrice > input {
  padding: 7px 10px;
}
.formItemBlock {
  background: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.headMaterial,
.inputMaterial {
  width: 32%;
}
.headName,
.inputName {
  width: 20%;
}
.headProvider,
.inputProvider {
  width: 20%;
}
.headPrice,
.inputPrice {
  width: 18%;
}




