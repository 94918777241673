.table {
  width: 718px;
  padding-top: 14px;
}

.headerTable, .contentRow {
  display: grid;
  grid-template-columns: 123px 129px 206px 1fr;
  grid-gap: 10px;
  grid-row-gap: 10px;
}

.headerTable > div,
.contentRow > .orderField {
  padding: 10px;
  background: #A8D3C9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.headerTable {
  margin-bottom: 10px;

  & > div {
    text-align: center;
  }
}

.contentRow {
  .orderField {
    background: #F9F9F9;
  }
}

.contentTable {
  display: grid;
  grid-row-gap: 10px;
}

.orderField, .orderField > a {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
}