.statusBlock {
  margin-bottom: 33px;
}

.buttons {
  margin-bottom: 11px;
}

.statusUser {
  display: grid;
  grid-template-columns: 153px 306px;

  & .userInput:not([type='radio']):not([type='checkbox']),
  .userValue,
  .userTitle {
    height: 31px;
    font-weight: 400;
    background: #fff;
    padding: 7px 10px;
    border-radius: 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    position: relative;
  }

  & .userTitle {
    font-weight: 500;
    background: #ccc;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  }

  & .userValue {
    padding: 0;
  }
}

.btnChange {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0096ff;
  background: none;
  padding: 5px 0;

  &:hover {
    cursor: pointer;
  }
}

.status {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 10;

  & .statusItem {
    padding: 8px 10px;
    background: #fff;

    &:hover {
      background: rgba(208, 231, 226, 1);
      cursor: pointer;
    }
  }
}

.activeStatus {
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 7px 10px;
  line-height: 17px;
}