.personalData {
  background: rgba(255, 255, 255, 0.61);
  border: 0.3px solid #76838F;
  border-radius: 10px;
  padding: 30px;

  @media only screen and (max-width: 575px) {
    padding: 15px;
  }
}

.personalContent {

}

.form {
  display: flex;
  flex-direction: column;

  & .formText, .field {
    width: 100%;
  }

  .field .error {
    bottom: 25px;
  }
}

.formTitle {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1E252B;
  margin-bottom: 50px;
}

.formText {
  min-height: 69px;
  padding: 10px 18px;
  background: #F9F9F9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000;
  resize: none;
  position: relative;

  &::placeholder {
    color: rgba(30, 37, 43, 0.42);
  }
}

.formTextarea {
  margin-bottom: 25px;
  position: relative;
}

.btnGreen {
  max-width: 165px;
  width: 100%;
  padding: 6px 15px;
  border-radius: 10px;
  background: #4C897A;
  border: 0.1px solid #76838F;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  align-self: flex-end;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}








