.container{
	position: fixed;
	right: 30px;
	z-index: 1000;
	// display: none;
	animation: open-prompt 0.5s forwards ease-out;
	// width: auto;

}
.prompt{
	max-width: 500px;
	position: relative;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #1E252B;
	padding: 30px 60px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, .25);
	border-radius: 10px;	
	background: #FFFFFF;
	
	&_success{
		border: 1px solid #4C897A;
		// display: block;
	}
	&_alarm{
		border: 1px solid #DF3839;
		// display: block;
	}
	&_warning{
		border: 1px solid #dbd815;
	}
	& p{
		position: absolute;
		top: 10px;
		right: 15px;
		color: gray;
		cursor: pointer;
		&:hover{
			color: #1E252B;
		}
	}
}



@keyframes open-prompt {
	0% {
	  opacity: 0;
	  top: 90px;
	  display: block;
	}
	100% {
	  opacity: 1;
	  top: 100px;

	}
}