.buttons {
  display: flex;
}

.buttonsDownload {
  display: flex;
  flex-direction: column;
  margin-right: 29px;
}

.buttonDownload,
.buttonUpload {
  width: 393px;
  min-height: 52px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  position: relative;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.41);
}

.buttonDownloadOutlay {
  background: #4C897A;
  margin-bottom: 20px;
}

.buttonDownloadMaterial {
  background: #909090;

  & a {
    color: inherit;
  }
}

.buttonDownload {
  display: flex;
  align-items: center;
  padding: 6px 57px;
}

.buttonUploadEdited {
  padding: 42px 33px 42px 80px;
  background: #76838F;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.41);
  cursor: pointer;

  & .imageWrap {
    left: 42px;
  }
}

.imageWrap {
  width: 21px;
  height: 26px;
  position: absolute;
  top: 50%;
  left: 21px;
  transform: translateY(-50%);

  & > svg {
    width: 100%;
    height: 100%;
  }
}
.imageWrapWarning {
  width: 42px;
  height: 36px;
  left: unset;
  right: 21px;
}
.input {
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.label {
  z-index: -1;
}

.modalTitle {
  padding-bottom: 20px;
  text-align: center;
  color: #ff0000;
}

.modal {
  padding: 0 20px 20px;
  max-width: 50vw;
  max-height: 70vh;
}

.closeModal {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
}