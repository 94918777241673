.menu {
  margin-bottom: 30px;
  border-bottom: 1px solid #76838F;
}

.tabs {
  display: flex;
}
.tabsItem {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #76838F;
  padding-bottom: 16px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
.tabsItemActive {
  color: #4C897A;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: -2px;
    background: #4C897A;
  }
}

.subTabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 28px;
}

.subTabsItem {
  margin-right: 5px;
  padding: 4px 9px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #555;

  &:active {
    color: inherit;
  }
}

.subTabsItemActive {
  background: rgba(168, 211, 201, 0.54);
  border-radius: 30px;
}