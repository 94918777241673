.footer{
	color: #FFFFFF;
	background: rgba(38, 50, 56, 0.79);
	height: 50px;
	width: 100%;
	padding-left: 85px;
	position: relative;
	&__down{
		text-align: center;
		padding: 15px 0 15px 0;
		span, .tabsLink{
			font-size: 12px;
			line-height: 15px;
			margin-right: 10px;
			&:last-child{
				margin-right: 0;
			}
			&:nth-child(2), &:nth-child(3){
				cursor: pointer;
				&:hover{
					color: #E5EEEC;
				}
			}
		}
	}
}
.tabsLink {
	color: #fff;
}

@media only screen and (max-width: 800px) {
	.footer{
		padding-left: 10px;
		&__down{
			padding: 15px 5%;
		}
	}
}
  
@media only screen and (max-width: 700px) {
	.footer{
		&__up{
			padding: 10px 5%;
		}
		&__down{
			padding: 10px 5% 10px 0;
		}
	}
} 