// стилизация таблицы
.tableOrder {
	display: grid;
	grid-template-areas: "header"
											 "content";
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-gap: 10px;
}
.header,
.contentRow {
	display: grid;
	grid-template-columns: 1.5fr 1.5fr 1.5fr 1.2fr 1.2fr 1.5fr;
	grid-gap: 10px;
}
.header {
	grid-area: header;
	grid-template-areas: "numbers compiler info status dateCreate .";

	.numbers,
	.compiler,
	.info,
	.status,
	.dateCreate,
	.sendToWork {
		background: #a8d3c9;
		border: none;
	}
}
.contentRow {
	grid-template-areas: "numbers compiler info status dateCreate sendToWork";
}
.content {
	grid-area: content;
	display: grid;
	grid-gap: 10px;
}
.numbers,
.compiler,
.info,
.status,
.dateCreate {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #1e252b;
	border-radius: 10px;
	padding: 5px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	word-break: break-word;
}
.numbers {
	grid-area: numbers;
}
.compiler {
	grid-area: compiler;
}
.info {
	grid-area: info;
}
.status {
	grid-area: status;
}
.dateCreate {
	grid-area: dateCreate;
}
.sendToWork {
	grid-area: sendToWork;
}
.orderView {
	display: block;
	width: 100%;
	height: 100%;
	padding: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #1e252b;
	background: #fff;

  &:hover {
		color: #517690;
		cursor: pointer;
	}
}
.sendToWork {
	height: 100%;

	& button {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		color: #fff;
		background: #4c897a;
		border-radius: 10px;
		padding: 5px;
		box-shadow: 0 3px 3px 0 rgba(8, 8, 8, .3);

		&:hover {
			color: #fff;
			background: lighten(#4c897a, 10%);
		}
	}
}
//-----

.orderArchive{
	padding: 0 150px;
	margin-bottom: 50px;
}
.orderArchive_title{
	margin-bottom: 50px;
}
.headlines_number{
	min-width: 44px !important;
}
.headlines_compiler, .headlines_info{
	width: 224px !important;
}
.headlines_status{
	width: 196px !important;
}
.headlines_dateStart, .headlines_dateEnd{
	width: 160px !important;
}

.orderArchive_modal{
	width: 648px;
	padding: 30px 0 !important;
	color: #1E252B;
	background: #F9F9F9;
	overflow-y: scroll;
	max-height: 95vh;
}
