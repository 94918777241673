@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@400;500;600;700&display=swap');
@import './style/buttons';
@import './components/content/orders/form-table';
@import './style/variable';
html, body, button, input{font-family: 'Montserrat', sans-serif;}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #f9f9fd;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: #a8d3c933;
}
*::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #f9f9fd;
}

input:-webkit-autofill{
  box-shadow:inset 0 0 0 1000px #fff;
}

h1{
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #1E252B;
  padding-top: 50px;
  text-align: center;
}

h2{
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #1E252B;
}

h3{
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
}

a.disabled {
  color: #5b5b5b;
  cursor: default;
  pointer-events: none;
}

//.modal_order{
//	width: 1020px;
//	padding: 45px !important;
//	overflow-y: scroll;
//	height: 95vh;
//}

.block-row{
  display:flex;
  flex-direction:column;
  height: calc(100vh + 50px);
}
.content{
  flex: 1 1 auto;
  padding-left: 85px;
  padding-top: 80px;
  background: #F9F9F9;
}
.container{
  max-width: 1174px; 
  margin: 0 auto;
}
.form-item-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1E252B;
  margin-bottom: 20px;
}
.create-order-title{
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #1E252B;
	margin:  0 0 50px 0;
}
.arrow {
  margin-right: 5px;
  transition: .2s all linear;
  transform: rotate(0deg);
}

.arrowActive {
  transition: .2s all linear;
  transform: rotate(-180deg);
}

// Табы
.menu {
  margin-bottom: 30px;
  border-bottom: 1px solid #76838F;
}

.tabs {
  display: flex;
}

.tabsItem {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #76838F;
  padding-bottom: 16px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.tabsItemActive {
  color: #4C897A;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: -2px;
    background: #4C897A;
  }
}

.private-office {
  padding: 0 150px;

  @media only screen and (max-width: 992px) {
    padding: 0 50px;
  }
}

.confidentialityText,
.termsText {
  ul {
    margin: 10px 0;
  }
  ul li {
    margin-bottom: 10px;
  }
  ul li li {
    padding-left: 10px;
  }
}
.termsTitle {
  margin-bottom: 15px;
}
.termsRedaction {
  text-align: right;
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .content{
    padding-left: 0px;
    padding-top: 75px;
  }
  h1 {
    font-size: 25px;
    line-height: 30px;
    padding-top: 30px;
}
}  

@media only screen and (max-width: 600px) {
  .content{
    padding-left: 0px;
    padding-top: 70px;
  }
  h1 {
    font-size: 20px;
    line-height: 30px;
    padding-top: 20px;
}
} 
@media only screen and (max-width: 500px){
  .content{
    padding-top: 65px;
  }
}