.adminPanelTitle {
  margin-bottom: 50px;
}

.adminPanelContent {
  min-width: 1000px;
  min-height: 50vh;
  padding: 50px;
  background: #fff;
  border: 0.3px solid #76838F;
  border-radius: 10px;
}

.menu {
  margin-bottom: 30px;
  border-bottom: 1px solid #76838F;
}

.tabs {
  display: flex;
}

.tabsItem {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #76838F;
  padding-bottom: 16px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.tabsItemActive {
  color: #4C897A;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: -2px;
    background: #4C897A;
  }
}