.commentary {
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
}
.commentaryWrap {
  display: flex;
  margin-bottom: 25px;
}
.commentaryTitle {
  margin-right: 20px;

}
.varinate {
  display: flex;
  align-items: center;
}
.varinateTitle {
  margin-right: 10px;
  padding: 4px;
  border: 1px solid #a8d3c9;
}
.select {
  width: 50px;
  height: 25px;
  padding: 4px;
  border: 1px solid grey;

  & + span {
    width: 160px;
  }
}
.selectWrap span.error {
  border: 2px solid red;
}
.commentaryInput {
  display: flex;
  margin-bottom: 25px;
}
.commentaryName {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  padding: 7px;
  margin-right: 10px;
  background: #a8d3c9;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}
.commentaryInputField {
  width: 100%;
}
.commentaryTextareaField {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 10px;
  resize: none;
}
.commentaryTextareaFieldWrap {
  width: 100%;
  min-height: 87px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}















