.btn {
  padding: 15px;
  border-radius: 10px;

  &--gradient {
    width: 100%;
    display: block;
    max-width: 315px;
    color: #1E252B;
    background: linear-gradient(93.06deg, #81C6B6 0%, #A8D3C9 100%);
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.25);
    transition: all 2s ease-in-out;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin: 0 auto;

    &:hover {
      background: linear-gradient(to left, #81C6B6 0%, #A8D3C9 100%);
    }
  }

  &:disabled {
    background: grey;
  }
}