.filter {
  position: relative;
}
.listPoints {
  width: 100%;
  padding: 5px 9px 9px;
  border: 2px solid #F8F8F8;
  border-radius: 0 0 10px 10px;
  background: #fff;
  position: absolute;
  top: 33px;
  left: 0;
  z-index: 300;

  & > li {
    padding: 8px 10px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1E252B;

    &:hover {
      background: #f5f5f5;
      cursor: pointer;
    }
  }
}