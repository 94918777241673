.storageTitle {
  margin-bottom: 40px;
}
.storageContent {
  min-width: 1000px;
  min-height: 50vh;
  padding: 50px;
  background: #fff;
  border: 0.3px solid #76838F;
  border-radius: 10px;
}
.menu {
  margin-bottom: 30px;
  border-bottom: 1px solid #76838F;
}