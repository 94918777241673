.modalContent {
  width: 600px;
  max-width: 70vw;
}
.titleWrap {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  margin-bottom: 24px;
}

.title {
  grid-column-start: 2;
  grid-column-end: 3;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1e252b;
}

.subtitle {
  grid-column-start: 3;
  grid-column-end: 4;
  text-align: end;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1e252b;
}

.buttons {
  margin-bottom: 20px;
}

.print {
  padding: 6px 15px;
  background: #4c897a;
  box-shadow: 0 2.3px 4.7px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}

.table {
  display: grid;
}

.tableHeaderItem {
  padding: 5px 10px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1e252b;
  background: #a8d3c9;
  box-shadow: 0 0 2.4px rgba(0, 0, 0, 0.25);

  &:first-child {
    border-radius: 6px 0 0 0;
  }

  &:last-child {
    border-radius: 0 6px 0 0;
  }
}

.tableHeader,
.tableContentRow {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
}

.tableContentRow {
  margin-bottom: 5px;
}

.tableContentItem {
  padding: 5px 10px;
  background: #fff;
  box-shadow: 0 0 2.4px rgba(0, 0, 0, 0.25);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1e252b;
  word-break: break-word;

  &:first-child {
    display: flex;
    align-items: center;
  }

  &:nth-child(2) {
    padding: 0;
  }

  &:last-child {
    display: flex;
    align-items: flex-end;
  }
}

.tableContentGood {
  padding: 5px 10px;
  box-shadow: 0 0 2.4px rgba(0, 0, 0, 0.25);
}

.tableFooter {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
}

.tableFooterItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  box-shadow: 0 0 2.4px rgba(0, 0, 0, 0.25);
  font-weight: 600;

  &:first-child {
    border-radius: 0 0 0 6px;
    background: #a8d3c9;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1e252b;
  }

  &:last-child {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    border-radius: 0 0 6px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1e252b;
    grid-column-start: 2;
    grid-column-end: 4;
  }
}

.reportEmpty {
  padding: 10px;
}

.footerTotal {
  padding: 5px 10px;
}

.close {
  width: 10px;
  height: 10px;
  content: '';
  position: absolute;
  top: 7px;
  right: 7px;

  &:hover {
    cursor: pointer;
  }
}