@import "./src/style/variable";

.table {
  display: grid;
  grid-gap: 10px;
}

// стилизация шапки таблицы
.header {
  display: grid;
  grid-template-columns: 88px repeat(9, 1fr);
  position: sticky;
  top: $header-height;
  z-index: 50;

  & > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #1E252B;
    text-align: center;
    padding: 7px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    background: #c4e6de;
    border: 1px solid rgba(98, 109, 119, 0.58);

    &:first-child {
      border-radius: 10px 0 0 0;
    }
    &:last-child {
      border-radius: 0 10px 0 0;
    }
  }
}

// Стилизация ячейки
.cell {
  padding: 7px 10px;
  height: 100%;
  display: flex;
  align-items: center;
  background: #f3f3f3;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  position: relative;
}

.arrow {
  position: relative;

  &:before {
    width: 12px;
    height: 2px;
    position: absolute;
    top: 50%;
    right: 13px;
    content: '';
    display: flex;
    background: #393939;
    transform: translateY(-50%) rotate(45deg);
  }
  &:after {
    width: 12px;
    height: 2px;
    position: absolute;
    top: 50%;
    right: 6px;
    content: '';
    display: flex;
    background: #393939;
    transform: translateY(-50%) rotate(-45deg);
  }
}

.waiting {
  background: #fab16e;
}

.new {
  background: #f9f9f9;
}

.assigned {
  background: #858585;
}

.progress {
  background: #faec6e;
}

.finish {
  background: #4c897a;
}

// выпадающий список обивщиков
.usersList {
  width: 100%;
  min-height: 30px;
  max-height: 150px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.19);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 20;
  overflow-y: auto;
}

.usersItem {
  height: 30px;
  padding: 7px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8e8e8e;
  text-align: left;

  &:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
}