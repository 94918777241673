/* sider--------------------------------------------------------- */
@import "./src/style/variable";

.sider, .siderRollUp {
	transition: all 500ms;
}

.sider.siderRollUp .sider__title{
	transition: all 300ms;
}

.sider .sider__title{
	transition: all 1100ms;
}

.sider{
	width: $sider-menu-width;
	height: 100vh;
	background-color: #263238;
	box-shadow: 3px 3px 5px #76838F;
	position: fixed;
	margin-top: 80px;
	z-index: 400;
	&__img{
		margin-right: 20px;
		min-width: 20px;
		width: 20px;
		img{
			width: 100%;
			filter: saturate(1000%) hue-rotate(170deg) invert(0.4) contrast(1.8);
		}
	}
	ul li{
		padding: 0 0 0 28px;
		height: 75px;
		width: 100%;
		display: flex;
		align-items: center;
		cursor: pointer;
		position: relative;
	}
	&__title{
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		color: rgba(255, 255, 255, 0.58);
		cursor: default;
		pointer-events: none;
	}
	&__tooltip{
		position: absolute;
		width: 182px;
		height: inherit;
		background: rgba(55, 71, 79, 0.94);
		color: rgba(255, 255, 255, 0.58);
		align-items: center;
		justify-content: center;
		left: 100%;
		opacity: 0;
		display: none;
	}
}

.list {
	width: 100%;
	height: calc(100% - 130px);
	overflow-Y: auto;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(33, 41, 46, 0.7);
	}
}

.siderRollUp{
	width: 85px;
	display: block;
}

.siderRollUp ul li .sider__title {
	opacity: 0;
}

.sider ul li:hover, .sider_active li{
	background: #37474F;
}

.sider ul li:hover .sider__img img, .sider_active li .sider__img img{
	filter: invert(0);
}

.sider ul li:hover .sider__title, .sider_active li .sider__title{
	color: #FFFFFF;
}

  /* знак вопроса */
.help{
	height: 50px !important;
	background: #21292E;
	position: absolute !important;
	bottom: calc(0% + 80px);
	left: 0;
	pointer-events: none;
	// потом убрать
	img{
		filter: invert(0.7);
	}
	p{
		color:rgba(85, 104, 113, 0.9)
	}
}

.siderRollUp ul li:hover .sider__tooltip{
	display: flex;
	animation: open-tooltip 0.5s forwards ease-out;
}

@keyframes open-tooltip {
	0% {
	  opacity: 0;
	  display: none;
	}
	100% {
	  opacity: 1;

	}
}

@media only screen and (max-width: 768px) {
	.siderRollUp ul li:hover .sider__tooltip{
		display: none;
	}
	.siderRollUp, .sider{
		width: 200px;
		margin-top: 75px;
		& ul li .sider__title {
			opacity: 1;
		}
	}
	.sider.siderRollUp{
		transform: translateX(-250px);
	}
	.sider{
		transform: translateX(0px);
	}
	.help{
		bottom: calc(0% + 75px);
	}
}

@media only screen and (max-width: 600px) {
	.siderRollUp, .sider{
		margin-top: 70px;
	}
	.help{
		bottom: calc(0% + 70px);
	}
}

@media only screen and (max-width: 500px) {
	.siderRollUp, .sider{
		margin-top: 65px;
	}
	.help{
		bottom: calc(0% + 65px);
	}
}