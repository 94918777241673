.privateOffice {
  display: flex;

  @media only screen and (max-width: 575px) {
    justify-content: space-between;
  }
}

.title {
  margin-bottom: 50px;
}

.privateOfficeTabs {
  width: 40%;

  @media only screen and (max-width: 575px) {
    width: 15%;
  }
}

.officeItem {
  width: 60%;
  max-width: 437px;

  @media only screen and (max-width: 575px) {
    width: 80%;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  margin: 0 65px 25px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(168, 211, 201, 0.28);

  &Item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #1E252B;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &Active {
      background: rgba(168, 211, 201, 0.19);
    }

    & img {
      margin-right: 10px;
    }

    @media only screen and (max-width: 575px) {
      justify-content: center;
    }
  }

  @media only screen and (max-width: 1200px) {
    margin-right: 30px;
  }

  @media only screen and (max-width: 575px) {
    margin-right: 0;
  }
}

.otherLinks {
  & a {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.56);
    margin-right: 15px;

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}
.privateOfficeIcon {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100px;
  }
}

.privateOfficeTitle {
  @media only screen and (max-width: 575px) {
    display: none;
  }
}
