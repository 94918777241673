
.searchInput {
  width: 300px;
  height: 38px;
  padding: 5px 12px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;

  &:after {
    display: flex;
    width: 20px;
    height: 20px;
    background: red;
    content: 'X';
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
  }
}
.close {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }
}

.selectable {
  position: absolute;
  top: 30px;
  left: 0;
  background: #ffffff;
  border: 1px solid #A8D3C9;
  z-index: 400;
}

div.dayPicker {
  top: 40px;
  left: 30px;
}