@import "./src/style/variable";

.header, .header-roll-up, .logo__title{
	transition: all 500ms;
}


.header{
	height: $header-height;
	width: 100%;
	background: #FFFFFF;
	box-shadow: 0 0 5px #76838F;
	position: fixed;
	display: flex;
	top:0;
	align-items: center;
	justify-content: space-between;
	z-index: 500;
	&__left, &__right, &__logo, &__menu,
	&__profile, &__burger{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
	&__logo{
		width: $sider-menu-width;
		transition: all 500ms;
		background-color: #21292E;
		box-shadow: 3px 3px 5px #76838F;
	}
	&__burger{
		height: auto;
		cursor: pointer;
		margin-left: 30px;
		animation: rotate 0.3s forwards ease-in-out;
		&_rotate{
			animation: rotate-burger 0.3s forwards ease-in-out;
			@keyframes rotate-burger {
				0% {
					
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(180deg);
				}
			}
		}
		&:hover img{
			filter: sepia() saturate(1000%) hue-rotate(170deg) invert(0.4) contrast(1.8);
		}
		@keyframes rotate {
			0% {
				
				transform: rotate(180deg);
			}
			100% {
				transform: rotate(0deg);
			}
		}
	}
	&__burger:hover, &__menuImg:hover, &__profile:hover{
		cursor: pointer;
	}
	&__right{
		margin-right: 80px;
		justify-content: space-between;
	}
	&__menuImg{
		border: transparent;
		img{
			&:hover{
				filter: sepia() saturate(1000%) hue-rotate(170deg) invert(0.4)contrast(1.8);
				transition: all 0ms;
			}
		}
	}
	&__profile{
		margin-left: 30px;
		border: transparent;
		position: relative;
	}
	&__notice{
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		&_is_read{
			position: absolute;
			top: 35%;
			right: -1.5px;
			border-radius: 50%;
			background-color: #f12424;
			width: 10px;
			height: 10px;
			box-shadow: 1px 0px 1px 0px #7c97cc;
		}
	}
	&__profileName{
		font-size: 16px;
		line-height: 20px;
		color: #37474F;
		padding-top: 3px;
	}
	&__profileImg{
		margin: 0 10px;
		width: 30px;
		height: 30px;
		border-radius: 5px;
		img{
			width: 100%;
			height: 100%;
			border-radius: 5px;
		}
	}
	&__profileShowDropdown{
		width: 8px;
		height: 8px;
		border: 1.5px solid #76838F;
		transform: rotate(45deg);
		border-top: transparent;
		border-left: transparent;
		&:hover{
			border-color: $hover-color;
		}
	}
}
 
.logo__img{
	height: 85px;
	width: 85px;
	display: flex;
	align-items: center;
	justify-content: center;
	&_adaptive{
		height: 75px;
		align-items: center;
		justify-content: center;
		display: none;
	}
 }
.logo__title{
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	border-left: 1px solid #FFFFFF;
	padding-left: 10px;
	padding-top: 5px;
	height: 28px;
	color: #FFFFFF;
	margin-right: 15px;
}

.headerRollUp{
	width: 85px;
	display: block;
	
	& .logo__title{
		display: none;
	}
}
.profileDropdown{
	left: 50%;
	width: 150px;
	li{
		height: 45px;
		padding-left: 10px;
		align-items: center;
		img{
			margin-right: 10px;
		}
		p{
			font-size: 12px;
			line-height: 15px;
			color: $border-gray;
		}
		&:hover{
			background: rgba(238, 241, 245, 0.79);
		}

	}
}
.noticeDropdown{
	right: -100%;
	width: 364px;
	&_title{
		padding: 12px 20px;
		text-align: right;
		font-weight: 500;
	}
	ul{
		box-shadow: 0px -0.3px 0px #76838f;
		max-height: 300px;
		overflow: auto;
		li{
			padding: 20px 30px 20px 20px;
			align-items: center;
			position: relative;
			font-size: 14px;
			line-height: 17px;
			color: #37474F;
			img{
				margin-right: 10px;
			}
		}
	}
}
.cross{
	position: absolute;
	top: 15px;
	right: 5px;
	cursor: pointer;
}
.profileDropdown, .noticeDropdown{
	position: absolute;
	background: #FFFFFF;
	display: none;
	opacity: 0;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	li{
		display: flex;
		justify-content: flex-start;
		box-shadow: 0px -0.3px 0px #76838f;

		button{
			background: red	;
		}
	}
}

.profileDropdownOpen, .noticeDropdownOpen{
	display: block;
	animation: dropdown-open 0.5s forwards ease-out;
}

@keyframes dropdown-open {
	0% {
		top: 50%;
		opacity: 0;
	}
	100% {
		  opacity: 1;
		  top: 101%;
	}
}


@media only screen and (max-width: 768px) {
	.header {
		height: 75px;
		&__right {
			// width: 330px;
			margin-right: 40px;
		}
		&__logo {
			width: 85px;
			display: block;
			box-shadow: none;
			& .logo__title{
				display: none;
			}
		}
	}
	.logo__img {
		display: none;
		&_adaptive{
			display: flex;
			background-color: #FFFFFF;
		}
	}
	.profileDropdown{
		left: 30%;
	}
}

@media only screen and (max-width: 760px) {
	.profileDropdown{
		left: 17%;
	}
}

@media only screen and (max-width: 600px) {
	.header {
		height: 70px;
		&__right {
			// width: 330px;
			margin-right: 40px;
		}
	}
	.logo__img {
		&_adaptive{
			height: 70px;
		}
	}
	.noticeDropdown{
		right: -400%;
	}

}

@media only screen and (max-width: 560px) {
	.header {
		&__right {
			width: 150px;
			margin-right: 30px;
		}
	}
	.header__profileName {
		display: none;
	}
	.header__profile{
		width: 100px;
	}
	.profileDropdown{
		left: -50%;
	}
}

@media only screen and (max-width: 500px) {
	.header {
		height: 65px;
		&__right {
			margin-right: 20px;
		}
		&__profile {
			p{
				font-size: 14px;
			}
		}
		.logo__img {
			&_adaptive{
				height: 65px;
			}
		}
	}
}

@media only screen and (max-width: 400px) {
	.header {
		&__right {
			width: 120px;
			margin-right: 10px;
		}
		&__profile {
			width: 80px;
		}
	}
	.profileDropdown{
		left: -100%;
	}
	.noticeDropdown{
		right: -475%;
		width: 315px;
	}
}

// @media only screen and (max-width: 330px) {
// 	.header {
// 		&__right {
// 			// width: 150px;
// 		}
// 	}
// }