.createUserModal {
  min-width: 300px;
  background: #f9f9f9;
  padding: 46px 50px 54px;
  border-radius: 10px;
  border: 0.3px solid #76838f;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.btnClose {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  padding: 15px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1e252b;
  margin-bottom: 36px;
  text-align: center;
}

.userData {
  display: grid;
  grid-template-areas: 'data userDocument';
  grid-template-columns: 470px 328px;
  grid-gap: 16px;
  margin-bottom: 31px;
}

.item {
  display: grid;
  grid-template-columns: 150px 270px;
  grid-template-rows: 30px;

  & .itemValue:not([type='radio']):not([type=checkbox]):not([type=checkbox]) {
    height: 100%;
    padding: 7px 10px;
    background: #fff;
    border-radius: 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  }
}

.itemTitle,
.itemValue {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  padding: 7px 10px;
}

.itemTitle {
  background: #ccc;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.rolesList {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 31px;
  left: 0;
  background: #f5f5f5;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.roles {
  position: relative;

  & .wrapField {
    height: 100%;
  }
}

.role {
  display: inline-block;
  padding: 8px 10px;

  &:hover {
    background: rgba(208, 231, 226, 1);
    cursor: pointer;
  }
}

.documentTitle {
  margin-bottom: 22px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1e252b;
}

.btnSave {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  padding: 10px 48px;
  color: #fff;
  background: #4a4a4a;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0 0 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}