.production {
  margin: 39px 0 0;
}

.orderModal{
  width: 648px;
  padding: 45px 0 !important;
  color: #1e252b;
  background: #f9f9f9;
  overflow-y: scroll;
  max-height: 95vh;
}