.personalData {
  background: rgba(255, 255, 255, 0.61);
  border: 0.3px solid #76838F;
  border-radius: 10px;
  padding: 30px;

  @media only screen and (max-width: 575px) {
    padding: 15px;
  }
}
.personalContent {
}
.accountTitle {
  width: 42%;
  font-weight: 500;

  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 10px;
  }
}
.dataItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.accountText {
  width: 58%;
  font-weight: 400;
  padding: 7px 10px;
  background: #F9F9F9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow-x: auto;

  @media only screen and (max-width: 575px) {
    width: 100%
  }
}
.accountTitle,
.accountText {
  font-size: 14px;
  line-height: 17px;
  color: #1E252B;
}

