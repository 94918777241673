.table {
  height: 800px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    background-color: #a0a0a0;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #c4c4c4;
  }
  &::-webkit-scrollbar-track{
    width: 14px;
    height: 14px;
    border-radius: 10px;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.header,
.contentRow {
  display: grid;
  grid-template-columns: 280px repeat(10, 135px);
}

.headerItem,
.contentItem {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e252b;
  padding: 7px;
  background: #ccc;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.headerName,
.contentItem {
  position: sticky;
  left: 0;
}

.contentItem {
  justify-content: flex-start;
  background-color: #fff;
}

.editingField,
.cell {
  width: 100%;
  height: 100%;
}

.btns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 17px;
}

.btnChange {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #555;
  min-width: 207px;
  padding: 6px 8px;
  background: #a2a2a2;
  border-radius: 5px;
}