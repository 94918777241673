.performersInfo {
  display: grid;
  grid-template-areas: 'header'
                       'content';
}

.header,
.content {
  display: grid;
  grid-template-columns: 1.04fr 2.56fr 1.5fr 1.5fr 1.87fr;
}

.header {
  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1e252b;
    text-align: center;
    padding: 7px;
    background: #ccc;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  }
}

.content {
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1e252b;
    text-align: center;
    padding: 7px;
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  }
  & > .id {
    justify-content: flex-start;
    padding-left: 43px;
    position: relative;

    & .active, .blocked, .delete {
      display: inline-block;
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      left: 11px;
      transform: translateY(-50%);
    }
    & .active {
      background: #4c897a;
      border-radius: 50%;
    }
    & .blocked {
      background: #faec6e;
      border-radius: 50%;
    }
    & .delete {

      &::after, &::before {
        content: '';
        width: 18px;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 0;
        background: red;
      }
      &::after {
        transform: translateY(-50%) rotate(45deg);
      }
      &::before {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
  & > .info {
    color: #517690;
    cursor: pointer;
  }
}

.modalMat {
  width: 100%;
  height: 100%;
  background: rgba(98, 98, 98, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}

.modal {
  padding: 30px 50px 78px;
  background: #f9f9f9;
  border: 0.3px solid #76838f;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1e252b;
  margin-bottom: 34px;
  text-align: center;
}

.buttons {
  margin-bottom: 11px;
}

.btnChange {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0096ff;
  background: none;
  padding: 5px 0;
}

.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background: transparent;
}

.userData {
  display: grid;
  grid-template-areas: 'data userDocument';
  grid-template-columns: 470px 368px;
  grid-gap: 16px;
}

.user {
  display: grid;
}

.userItem {
  height: 31px;
  display: grid;
  grid-template-columns: 153px 271px;
}
